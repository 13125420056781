import React, { useState } from 'react';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const withFormValues = (WrappedComponent) => {
  return (props) => {
    const [values, setValues] = useState({ name: '', email: '', message: '' });
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(false);

    const handleChange = (name) => (event) => {
      setValues({ ...values, [name]: event.target.value });
    };

    const handleSubmit = () => {
      if (!loading) {
        post({ formValues: values });
        setLoading(true);
      }
    };

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setError(false);
    };

    const post = (data) => {
      console.log('test');
      fetch('/contact', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: data ? JSON.stringify(data) : null // body data type must match "Content-Type" header
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.success) setSubmitted(true);
          else setError(true);
        });
    };

    return (
      <>
        <WrappedComponent
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          submitted={submitted}
          {...props}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          autoHideDuration={6000}
          open={error}
          onClose={handleClose}
        >
          <Alert severity="error">
            Något gick fel. Pröva igen eller kom tillbaks senare.
          </Alert>
        </Snackbar>
      </>
    );
  };
};

export default withFormValues;
