import React from "react";
import ReactDOM from "react-dom";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Modal from "react-modal";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { blueTheme } from "./themes/Themes";
import { BrowserRouter as Router } from "react-router-dom";
import { connectCC } from "./plugins/cookie_consent";
import { withGA, GAEvent, GADisable } from "./plugins/google_analytics";

Modal.setAppElement("#root");


const handleCCInit = status => {
};

const handleCCStatusChange = (status, chosenBefore) => {
  if (status == "allow" && !chosenBefore) {
    GAEvent({
      category: "Cookie Consent",
      action: "User gave consent to using cookies."
    });
  } else GADisable();
};

const wraps = [withGA];

const AppWithCC = connectCC(handleCCInit, handleCCStatusChange, wraps)(App);

ReactDOM.render(
  <MuiThemeProvider theme={blueTheme}>
    <Router>
      <AppWithCC />
    </Router>
  </MuiThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();