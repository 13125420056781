import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

const trackingId = "UA-173277679-1";

export const GAInit = () => {
  if (!window.gaInit) {
    ReactGA.initialize(trackingId); // put your tracking id here
    window[`gaInit`] = true;
  }
  window[`ga-disable-${trackingId}`] = false;
};

export const GADisable = () => {
  window[`ga-disable-${trackingId}`] = true;
};

export const GAPageView = page => {
  ReactGA.set({ page: page }); // Update the user's current page
  ReactGA.pageview(page); // Record a pageview for the given page
};

export const GAEvent = args => {
  ReactGA.event(args);
};

export const GALogPageViews = history => {
  if (!window.gaInit) {
    history.listen(location => {
      const page = location.pathname || window.location.pathname;
      GAPageView(page);
      console.log(`Page View logged for: ${page}`);
    });
  }
};

export const withGATracker = WrappedComponent => {
  return props => {
    const history = useHistory();

    useEffect(() => {
      // Initialize Cookie Consent popup
      GAPageView(history.location.pathname);
      GALogPageViews(history);
    }, [history]);

    return <WrappedComponent {...props} />;
  };
};

export const withGA = Component => {
  return props => {
    useEffect(() => {
      GAInit();
    }, []);

    const WrappedComponent = withGATracker(Component);

    return <WrappedComponent {...props} />;
  };
};
