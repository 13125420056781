import {
  PrimaryButton as PrimaryButtonBase,
  SecondaryButton as SecondaryButtonBase
} from 'components/misc/Buttons.js';

import CustomerLogoImage1 from '../../images/customer_logos/gothenburg_horse_show_logo.svg';
import CustomerLogoImage2 from '../../images/customer_logos/nordea_open_logo.png';
import CustomerLogoImage3 from '../../images/customer_logos/america_vs_europe_logo.jpg';
import DesignIllustration from '../../images/freepik/rafiki/friendship.svg';
import Header from '../headers/light.js';
import { Link } from 'react-scroll';
import React from 'react';
import { ReactComponent as SvgDecoratorBlob1 } from '../../images/svg-decorator-blob-1.svg';
import { css } from 'styled-components/macro';
import styled from 'styled-components';
import tw from 'twin.macro';

//eslint-disable-next-line

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-4 lg:mt-0 inline-block w-56 tracking-wide text-center py-5`;
const SecondaryButton = tw(
  SecondaryButtonBase
)`m-4 lg:mt-0 inline-block w-56 tracking-wide text-center py-5`;

const LogoContainer = styled.div`
  ${tw`flex flex-wrap -mb-4`}
  div {
    ${tw`w-1/3 text-center`}
  }

  img {
    ${tw`h-20 opacity-50 m-auto`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-10 lg:mt-12`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500 mb-2`}
  }
`;

export default ({
  heading = 'Better, Faster and Cheaper Cloud.',
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
  imageSrc = 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
  imageDecoratorBlob = true,
  primaryButtonUrl = 'https://google.com',
  primaryButtonText = 'Kom igång',
  buttonRounded = true,
  testimonial = {
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    customerName: 'Charlotte Hale',
    customerCompany: 'Delos Inc.'
  },
  roundedHeaderButton
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <TwoColumn>
          <LeftColumn>
            <Heading>
              Evenemang ska vara <span tw="text-primary-500">enkelt.</span>
            </Heading>
            <Paragraph>
              Evenemang ska kunna arrangeras utan krångel. Qufus gör
              funktionärshanteringen enkel och ger mer tid över till annat.
            </Paragraph>
            <PrimaryButton
              as={Link}
              to={'contact-form'}
              smooth
              offset={-350}
              css={buttonRoundedCss}
            >
              {primaryButtonText}
            </PrimaryButton>
            <SecondaryButton
              as="a"
              href={'https://app.qufus.se'}
              smooth
              offset={-350}
              css={buttonRoundedCss}
            >
              {'Till appen'}
            </SecondaryButton>
            <CustomersLogoStrip>
              <LogoContainer>
                <div>
                  <img src={CustomerLogoImage1} alt="Customer logo 1" />
                </div>
                <div>
                  <img src={CustomerLogoImage2} alt="Customer logo 2" />
                </div>
                <div>
                  <img src={CustomerLogoImage3} alt="Customer logo 3" />
                </div>
              </LogoContainer>
            </CustomersLogoStrip>
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl"
                src={DesignIllustration}
                alt="Design Illustration"
              />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
