import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import { PrimaryButton } from "../misc/Buttons.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const FeaturesContainer = tw.div`mt-16 lg:mt-0`;
const Features = styled.div``;
const Feature = tw.div`max-w-md lg:max-w-none mx-auto lg:mx-0 flex flex-col items-center lg:items-stretch lg:flex-row`;

const FeatureImageSlider = tw(Slider)`w-full lg:w-6/12 flex-shrink-0 `;
const FeatureTextSlider = tw(Slider)``;
const FeatureText = tw.div`outline-none md:mb-20`;

const FeatureList = tw.ul`mt-6 ml-6 leading-loose`;
const FeatureItem = tw.li`flex items-center`;
const FeatureItemIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureItemText = tw.p`ml-2 font-medium text-secondary-100`;

const ImageAndControlContainer = tw.div`relative outline-none`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-cover bg-center h-80 sm:h-96 lg:h-144`,
]);

const ControlContainer = tw.div`absolute bottom-0 right-0 px-6 py-4`;
const ControlButton = styled(PrimaryButton)`
  ${tw`mx-3 rounded-full text-gray-100 p-2`}
  svg {
    ${tw`w-5 h-5`}
  }
`;
const ControlDot = tw.button`rounded-full h-3 w-3 mx-3 bg-gray-300`;

const TextContainer = styled.div((props) => [
  tw`flex flex-col w-full lg:w-6/12 relative`,
  props.textOnLeft ? tw`lg:pr-12 lg:order-first` : tw`lg:pl-12 lg:order-last`,
]);

const Subheading = tw(SubheadingBase)`mb-4`;
const HeadingTitle = tw(SectionHeading)`lg:text-left leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const QuotesLeft = tw(
  QuotesLeftIcon
)`w-6 h-6 opacity-75 text-primary-500 inline-block mr-1 -mt-3`;
const QuotesRight = tw(
  QuotesRightIcon
)`w-6 h-6 opacity-75 text-primary-500 inline-block ml-1 -mt-3`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default ({ subheading = "", slides = [], textOnLeft = false }) => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [imageSliderRef, setImageSliderRef] = useState(null);
  const [textSliderRef, setTextSliderRef] = useState(null);

  return (
    <Container id="main-features">
      <Content>
        <Subheading>{subheading}</Subheading>
        <FeaturesContainer>
          <Features>
            <Feature>
              <TextContainer textOnLeft={textOnLeft}>
                <FeatureTextSlider
                  arrows={false}
                  ref={setTextSliderRef}
                  asNavFor={imageSliderRef}
                  fade={true}
                  pauseOnFocus
                  pauseOnHover
                >
                  {slides.map((slide, index) => (
                    <FeatureText key={index}>
                      <HeadingInfo
                        tw="text-center mb-4 lg:block lg:text-left"
                        heading={slide.heading}
                        description={slide.description}
                      />
                      <FeatureList>
                        {slide.featureList
                          ? slide.featureList.map((feature, index) => (
                              <FeatureItem key={index}>
                                <FeatureItemIcon />
                                <FeatureItemText>{feature}</FeatureItemText>
                              </FeatureItem>
                            ))
                          : null}
                      </FeatureList>
                    </FeatureText>
                  ))}
                </FeatureTextSlider>
                <ControlContainer>
                  <ControlButton onClick={imageSliderRef?.slickPrev}>
                    <ChevronLeftIcon />
                  </ControlButton>
                  {/*slides.map((slide, index) => (
                    <ControlDot />
                  ))*/}
                  <ControlButton onClick={imageSliderRef?.slickNext}>
                    <ChevronRightIcon />
                  </ControlButton>
                </ControlContainer>
              </TextContainer>
              <FeatureImageSlider
                arrows={false}
                ref={setImageSliderRef}
                asNavFor={textSliderRef}
                fade={true}
                autoplay
                autoplaySpeed={10000}
                pauseOnFocus
                pauseOnHover
              >
                {slides.map((slide, index) => (
                  <ImageAndControlContainer key={index}>
                    <Image imageSrc={slide.imageSrc} />
                  </ImageAndControlContainer>
                ))}
              </FeatureImageSlider>
            </Feature>
          </Features>
        </FeaturesContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ heading, description, ...props }) => (
  <div {...props}>
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
