import AnimationRevealPage from 'helpers/AnimationRevealPage.js';
import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg';
import CollaborateSVG from '../images/freepik/rafiki/collaborate.svg';
import ContactUsFormBase from 'components/forms/SimpleContactUs';
import CreateSVG from '../images/freepik/rafiki/create.svg';
import Fade from 'react-reveal';
import FeatureWithSteps from 'components/features/TwoColWithSteps.js';
import Features from 'components/features/ThreeFeaturesWithHeader.js';
import Footer from 'components/footers/SimpleThreeColumn';
import GetStarted from 'components/cta/GetStarted';
import Hero from 'components/hero/TwoColumnWithInput.js';
import HiringSVG from '../images/freepik/rafiki/hiring.svg';
import MainFeature from 'components/features/TwoColWithCarousel2.js';
import MainFeature2 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg';
import React from 'react';
import SuggestedCustomers from 'components/features/DashedBorderSixFeatures.js';
import { css } from 'styled-components/macro'; //eslint-disable-line
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png';
import prototypeIllustrationImageSrc from 'images/prototype-illustration.svg';
import tw from 'twin.macro';
import withFormValues from '../hocs/Form.js';

const ContactUsForm = withFormValues(ContactUsFormBase);

const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
const HighlightedText = tw.span`text-primary-500`;

const slides = [
  {
    heading: 'Kom igång enkelt',
    description:
      'Som ny eller gammal användare är det alltid lika enkelt att komma igång med Qufus. Vid uppstartsprocessen fyller ni själva i arrangemangets grunduppgifter och skapar de grupper som ska ingå. Varje grupp kan tilldelas en eller flera gruppledare som är ansvariga för gruppens medlemmar och kan behandla ansökningar till denna. Andra medlemmar från organisationens kan även bjudas in och tilldelas rätt behörighet för att kunna delta i arbetet.',
    imageSrc: CreateSVG,
    featureList: [
      'Simpel uppstartsprocess',
      'Skapa samtliga funktionsgrupper',
      'Tilldela behörigheter'
    ]
  },
  {
    heading: 'Samla in ansökningar',
    description:
      'Qufus gör det enkelt att samla in ansökningar och hitta rätt personer för ert arrangemang. Med det inbyggda verktyget kan ni skapa och anpassa egna ansökningsformulär för att se till att rätt information samlas in. När ansökan väl är öppen är det lätt att få en överblick på alla ansökningar samt dess nuvarande status i rekryteringsprocessen. Ansökningarna bearbetas sedan i turordning utifrån de sökandes gruppval tills dess att alla val har behandlats. ',
    imageSrc: HiringSVG,
    featureList: [
      'Skapa egna ansökningsformulär',
      'Bearbetning utifrån turordning av gruppval',
      'Verktyg för behandling av ansökningar, t.ex. sök- och kommentarsfunktion'
    ]
  },
  {
    heading: 'Lätt att samarbeta',
    description:
      'När ansökningarna har bearbetats finns det en mängd verktyg för att underlätta samarbetet mellan organisationen och arrangemangets medlemmar. På den gemensamma arrangemangssidan kan organisationen och medlemmarna skapa inlägg och diskutera dessa i tillhörande kommentarsfält. Organisationen har även möjlighet att ladda upp viktiga dokument och skicka ut information till en eller flera grupper via mejl. Alla medlemmars information finns sammanställd på en särskild sida endast avsedd för organisationen, där filtrering och sökning gör det lätt att få fram den information som eftersöks. Informationen går därefter att exportera i CSV-format.',
    imageSrc: CollaborateSVG,
    featureList: [
      'Gemensam arrangemangssida',
      'Skapa egna inlägg',
      'Ladda upp filer',
      'Kommunikationsverktyg',
      'Sammanställning och filtrering av medlemmarnas information',
      'Export av information till CSV-format'
    ]
  }
];

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => {
  return (
    <StyledDiv>
      <Hero roundedHeaderButton={true} />
      <Fade>
        <Features
          subheading={<Subheading>Vad är Qufus?</Subheading>}
          heading={
            <>
              Ett mångsidigt{' '}
              <HighlightedText>funktionärssystem.</HighlightedText>
            </>
          }
          description={
            'Qufus är ett webbaserat system för dig som hanterar volontärer, funktionärer eller annan frivilligpersonal.'
          }
        />
        <SuggestedCustomers
          heading={
            <>
              Användningsområdena är <span tw="text-primary-500">många.</span>
            </>
          }
          subheading={<Subheading>Vem är Qufus för?</Subheading>}
        />
        <GetStarted
          text={'Intresserad av att använda Qufus för ditt evenemang?'}
          primaryButton={{
            text: 'Kontakta oss nedan',
            to: 'contact-form'
          }}
        />
        <MainFeature
          subheading={<Subheading>FUNKTIONALITET</Subheading>}
          slides={slides}
          imageBorder={true}
          imageDecoratorBlob={true}
          textOnLeft={true}
        />
        {/*
        <FeatureWithSteps
          subheading={<Subheading>STEG</Subheading>}
          heading={
            <>
              Lätt att <HighlightedText>komma igång.</HighlightedText>
            </>
          }
          textOnLeft={false}
          imageSrc={macHeroScreenshotImageSrc}
          imageDecoratorBlob={true}
          decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
        />
        <MainFeature2
          subheading={<Subheading>VALUES</Subheading>}
          heading={
            <>
              We Always Abide by Our{" "}
              <HighlightedText>Principles.</HighlightedText>
            </>
          }
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: MoneyIcon,
              title: "Affordable",
              description:
                "We promise to offer you the best rate we can - at par with the industry standard.",
              iconContainerCss: tw`bg-green-300 text-green-800`,
            },
            {
              Icon: BriefcaseIcon,
              title: "Professionalism",
              description:
                "We assure you that our templates are designed and created by professional designers.",
              iconContainerCss: tw`bg-red-300 text-red-800`,
            },
          ]}
        />
        */}
        <ContactUsForm
          heading="Intresserad av att använda Qufus?"
          subheading={
            <>
              Fyll i formuläret nedan, så hör vi av oss så snart som möjligt!
              Det går även bra att skicka ett mail till{' '}
              <a href="mailto: info@qufus.se">info@qufus.se</a>
            </>
          }
          submittedHeading="Tack för ditt meddelande!"
          submitButtonText="Skicka"
        />
        {/*
        <FAQ
          subheading={<Subheading>FRÅGOR OCH SVAR</Subheading>}
          heading={
            <>
              Har du <HighlightedText>frågor?</HighlightedText>
            </>
          }
          description={
            <>Här nedan hittar du svar på några av de vanligaste frågorna.</>
          }
          faqs={[
            {
              question: "Are all the templates easily customizable ?",
              answer:
                "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              question:
                "How long do you usually support an standalone template for ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              question: "What kind of payment methods do you accept ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              question:
                "Is there a subscribption service to get the latest templates ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              question: "Are the templates compatible with the React ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
            {
              question: "Do you really support Internet Explorer 11 ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
            },
          ]}
        />
        */}
      </Fade>
      <Footer />
    </StyledDiv>
  );
};
