import React, { useEffect } from "react";

export const initCC = ({ handleInit, handleStatusChange }) =>
  window.cookieconsent.initialise({
    palette: {
      popup: {
        background: "#efefef",
        text: "#404040"
      },
      button: {
        background: "#8ec760",
        text: "#ffffff"
      }
    },
    theme: "classic",
    type: "opt-in",
    position: "top",
    content: {
      message:
        "Den här webbsidan använder cookies för att möjliggöra viktiga funktioner på sidan, bland annat analysverktyg, inriktning och personanpassning. Du kan ändra inställningarna när som helst eller acceptera standardinställningarna.",
      dismiss: "Jag vill inte använda cookies",
      deny: "Jag vill inte använda cookies",
      allow: "Acceptera",
      link: "Datalagringspolicy",
      policy: "Cookie-policy"
    },
    onStatusChange: handleStatusChange,
    onInitialise: handleInit
  });

export const connectCC = (handleInit, handleStatusChange, wraps) => {
  // Returns HOC that wraps an input Component with a list of HOCs
  return Component => {
    return props => {
      const [cookiesEnabled, setCookiesEnabled] = React.useState(false);

      useEffect(() => {
        // Initialize Cookie Consent popup
        initCC({
          handleInit: status => {
            if (status == "allow") {
              setCookiesEnabled(true);
            }
            handleInit(status);
          },
          handleStatusChange: (status, chosenBefore) => {
            if (status == "allow" && !chosenBefore) {
              setCookiesEnabled(true);
            } else if (!chosenBefore) setCookiesEnabled(false);
            handleStatusChange(status, chosenBefore);
          }
        });
      }, []);

      var WrappedComponent;

      if (cookiesEnabled) {
        wraps.forEach(withWrap => {
          WrappedComponent = withWrap(Component);
        });
      } else WrappedComponent = Component;

      return <WrappedComponent {...props} />;
    };
  };
};
