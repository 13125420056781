import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import { Subheading as SubheadingBase } from "components/misc/Headings.js";

import { coloredIcon } from "components/misc/Icons.js";

import { Speaker, Award, Home, Briefcase } from "react-feather";

const SpeakerIcon = coloredIcon(Speaker)
const AwardIcon = coloredIcon(Award)
const HomeIcon = coloredIcon(Home)
const BriefcaseIcon = coloredIcon(Briefcase)

const Container = tw.div`relative`;

const ColumnContainer = styled.div`
  ${tw`flex items-center justify-center flex-wrap max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;
const Subheading = tw(SubheadingBase)`mb-4`;

const Column = styled.div`
  ${tw`px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-sm items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 min-h-72`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ heading = null, subheading = null }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    { icon: <SpeakerIcon />, title: "Evenemang", description: "Rekrytering och hantering funktionärer eller volontärer vid exempelvis festivaler, kultur- och idrottsevenemang." },
    { icon: <AwardIcon />, title: "Organisationer", description: "Hantera flera licenser som distribueras ut till olika arrangörer inom en organisation." },
    { icon: <HomeIcon />, title: "Kommuner", description: "Samordning och beredskap av temporära resurser vid till exempel anordning av val eller vid kriser." },
    { icon: <BriefcaseIcon />, title: "Mässor", description: "Utställningar, konvent, konferenser eller andra typer av mässor som anordnas med hjälp av volontärer." },
  ];

  return (
    <Container>
      <ColumnContainer>
        <Subheading>{subheading}</Subheading>
        <Heading>{heading}</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                {card.icon}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
